import { render, staticRenderFns } from "./malluserslist.vue?vue&type=template&id=ec502e82&scoped=true"
import script from "./malluserslist.vue?vue&type=script&lang=js"
export * from "./malluserslist.vue?vue&type=script&lang=js"
import style0 from "./malluserslist.vue?vue&type=style&index=0&id=ec502e82&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ec502e82",
  null
  
)

export default component.exports