import { render, staticRenderFns } from "./malluserdetails.vue?vue&type=template&id=389e7482&scoped=true"
import script from "./malluserdetails.vue?vue&type=script&lang=js"
export * from "./malluserdetails.vue?vue&type=script&lang=js"
import style0 from "./malluserdetails.vue?vue&type=style&index=0&id=389e7482&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "389e7482",
  null
  
)

export default component.exports